// PF API (realne-api)
export const PF_API_URL = process.env.REACT_APP_PF_API_URL || 'http://localhost:20002'

// PPA App API (realne-ppa-console)
export const PPA_API_URL = process.env.REACT_APP_PPA_API_URL || 'http://localhost:20002'

// Auth API
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || ''
export const AUTH_API_KEY = process.env.REACT_APP_AUTH_API_KEY || ''

// Monitoring App (realne-monitoring)
export const MONITORING_API_URL = process.env.REACT_APP_MONITORING_API_URL || 'http://localhost:20002'
export const MONITORING_API_KEY = process.env.REACT_APP_MONITORING_API_KEY || 'da2-fakeApiId123456'

export const SETTING_APP_URL = process.env.REACT_APP_SETTING_APP_URL || ''

// Amplify
export const AMPLIFY_CONFIG = {
    Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
    },
    // aws_appsync_graphqlEndpoint: process.env.REACT_APP_AMPLIFY_APP_GRAPHQL_ENDPOINT,
    // aws_appsync_region: process.env.REACT_APP_AMPLIFY_REGION,
    // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    oauth: {
        domain: process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN,
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${AUTH_API_URL}/logout`,
        responseType: 'code',
    },
}

export const THUMBNAIL_FOLDER = 'thumbnail'

export const S3_CONFIG = {
    bucketName: process.env.REACT_APP_BUCKET_NAME || '',
    dirName: THUMBNAIL_FOLDER /* optional */,
    region: process.env.REACT_APP_REGION || '',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY || '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
}

export const DYNAMODB_CONFIG = {
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY || '',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
    },
    region: process.env.REACT_APP_REGION || '',
}
