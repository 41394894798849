import { createMuiTheme } from '@material-ui/core/styles'

export const BaseTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#6180AB',
        },
        common: {
            white: '#fff',
        },
        grey: {},
    },
    typography: {},
})
