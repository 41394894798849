import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { PF_API_URL, PPA_API_URL, MONITORING_API_URL, MONITORING_API_KEY } from 'constants/config'
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import { getAccessToken, logoutManually } from 'hooks/useAuth'
import { HttpStatusCode } from 'constants/enum'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (networkError && 'statusCode' in networkError && networkError.statusCode === HttpStatusCode.Unauthorized) {
            logoutManually()
        }
    }
})

const authMiddleware = setContext((_, { headers }) =>
    getAccessToken().then((token) => {
        return {
            headers: {
                ...headers,
                authorization: token || null,
            },
        }
    }),
)

export const client = new ApolloClient({
    link: ApolloLink.from([
        authMiddleware,
        errorLink,
        new MultiAPILink({
            endpoints: {
                PF_API: PF_API_URL,
                PPA_API: PPA_API_URL,
                MONITORING_API: MONITORING_API_URL,
            },
            createHttpLink: () => new HttpLink(),
            getContext: (endpoint, getCurrentContext) => {
                const { headers } = getCurrentContext()
                switch (endpoint) {
                    case 'PF_API':
                    case 'PPA_API':
                        return {
                            headers,
                        }

                    case 'MONITORING_API':
                        return {
                            headers: {
                                'x-api-key': MONITORING_API_KEY,
                            },
                        }

                    default:
                        return {}
                }
            },
        }),
    ]),
    cache: new InMemoryCache(),
})
