import { Box, makeStyles } from '@material-ui/core'
import { AppEnv } from 'constants/env'

const ENV_LABEL_CONFIG: { [key in Exclude<AppEnv, AppEnv.Prod>]: { label: string; bgColor: string } } = {
    [AppEnv.Dev]: {
        label: '開発環境',
        bgColor: '#22AF22',
    },
    [AppEnv.Stg]: {
        label: '検証環境',
        bgColor: '#9A1AB1',
    },
}

const EnvLabel = ({ env }: { env: AppEnv }) => {
    const classes = useStyles()
    if (env === AppEnv.Prod) return null

    const { label, bgColor } = ENV_LABEL_CONFIG[env] ?? {}

    return (
        <Box className={classes.envLabel} style={{ backgroundColor: bgColor }}>
            {label}
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    envLabel: {
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: 96,
        height: 24,
        boxSizing: 'border-box',
        color: '#ffffff',
        fontSize: 12,
        fontFamily: 'Hiragino Sans, sans-serif',
        fontWeight: 600,
        borderRadius: 20,
        marginRight: 24,
        padding: '4px 24px',
        boxShadow: 'none',
    },
}))

export default EnvLabel
