import axios from 'axios'
import { AUTH_API_KEY, AUTH_API_URL } from 'constants/index'

const axiosClient = axios.create({
    baseURL: AUTH_API_URL,
    headers: {
        'content-type': 'application/json',
        'x-api-key': AUTH_API_KEY,
    },
})

axiosClient.interceptors.response.use(
    function (response) {
        if (response && response.data) {
            return response.data
        }
        return response
    },
    function (error) {
        return Promise.reject(error)
    },
)

export default axiosClient
