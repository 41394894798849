export const PATH_NAME = {
    // COMMON PATH
    HOME: '/',
    HOME2: '/home2',
    LOGIN: '/login',
    LOGOUT: '/logout',
    // END COMMON PATH
    SITE: {
        INDEX: '/sites',
        DETAIL: '/sites/:id',
    },
    // WARNING: Setting App was used another domain.
    SETTING_APP: {
        PPA_OPERATOR: {
            INDEX: '/ppa-operators',
            DETAIL: '/ppa-operators/:id',
            CREATE: '/ppa-operators/create',
        },
        COMPANY: {
            INDEX: '/companies',
            CREATE: '/companies/create',
            DETAIL: '/companies/:id',
            INFO: '/companies-info',
        },
        SITE: {
            INDEX: '/sites',
            MODIFY: '/sites/create',
            MODIFY_DETAIL: '/sites/:id',
            EQUIPMENT_MODIFY: '/sites/:id/equipment/:equipmentId',
        },
        USER: {
            INDEX: '/users',
            DETAIL: '/users/:id',
            CREATE: '/users/create',
        },
        NO_AUTHORIZATION: '/no-authorization',
        // BASIC_CONFIG: {
        //     INDEX: '/app-list',
        // },
    },
    PAYMENT: {
        INDEX: '/payments',
        INVOICE: '/payments/:id/invoice',
    },
    MAINTENANCE_COMPANY: {
        INDEX: '/maintenance-company',
        CREATE: '/maintenance-company/create',
        DETAIL: '/maintenance-company/:id',
    },
    INCIDENT: {
        INDEX: '/incident',
        DETAIL: '/incident/:id',
        CREATE: '/incident/create',
    },
    INFORMATION_LIST: {
        INDEX: '/information-list',
    },
    DOWNLOAD: {
        INDEX: '/download',
    },
    PROJECT_MANAGEMENT: {
        INDEX: '/project-management',
        DETAIL: '/project-management/:id',
        CREATE: '/project-management/create',
        EDIT: '/project-management/:id/edit',
        MULTIPLE_PROJECTS: '/project-management/multiple-projects/:id',
        EDIT_ACTUAL_COST: '/project-management/edit-actual-cost',
    },
    NOTE: {
        INDEX: '/notes',
    },
    SCHEDULE: {
        INDEX: '/schedule',
    },
    MONITOR_SETTINGS: {
        INDEX: '/monitor-settings',
    },
}
