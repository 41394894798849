import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AppState = {
    filter: {
        companyId: string
        keyword: string
        level: string
        filter: object
        vendorId: string
    }
    type: string
}

const initialState: AppState = {
    filter: {
        companyId: '',
        keyword: '',
        level: '',
        filter: {},
        vendorId: '',
    },
    type: '',
}

const filterSlice = createSlice({
    name: 'filter',
    initialState: initialState,
    reducers: {
        setFilterSearch(state: AppState, action: PayloadAction<any>) {
            state.filter = action.payload
        },
        setType(state: AppState, action: PayloadAction<string>) {
            state.type = action.payload
        },
    },
    extraReducers: {},
})

const { actions, reducer } = filterSlice
export const { setFilterSearch, setType } = actions
export default reducer
