import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { USER_LEVEL_VALUES } from 'constants/common'
import { LevelValue, UserLevel } from 'constants/enum'
import { get } from 'lodash'

export const accountScopesSelector = createSelector(
    (state: RootState) => state.auth,
    // TODO: use scopes data instead of idScopes data
    (auth) => auth.current?.idScopes ?? [],
)
export const accountNameSelector = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.current?.account?.name ?? '',
)

export const isAdminSelector = createSelector(
    (state: RootState) => state.auth,
    (auth) => {
        let isAdmin = false
        const scopes = get(auth, 'current.idScopes', [])
        scopes.forEach((scope: string) => {
            const [level] = scope.split('.')
            if (level === UserLevel.Admin) {
                isAdmin = true
            }
        })
        return isAdmin
    },
)

export const roleScope = createSelector(
    (state: RootState) => state.auth,
    (auth) => {
        const scopes = get(auth, 'current.idScopes', [])
        const hasRole = (items: any, roleName: any) => {
            return !!items.filter((item: any) => item.split('.')[1] === roleName).length
        }
        return {
            demoRole: hasRole(scopes, 'demo'),
            fullRole: hasRole(scopes, 'full'),
        }
    },
)

export const highestLevelSelector = createSelector(
    (state: RootState) => state.auth,
    (auth) => {
        let levelName: string = UserLevel.Site
        const levelValue = LevelValue.Site
        const scopes = get(auth, 'current.idScopes', [])

        scopes.forEach((scope: string) => {
            const [level] = scope.split('.')
            const levelObj = USER_LEVEL_VALUES.find((o) => o.name === level)
            const value = get(levelObj, 'value', levelValue)
            const name = get(levelObj, 'name', levelName)

            // Set level name if it's higher level
            if (value > levelValue) {
                levelName = name
            }
        })

        return levelName
    },
)

export const highestScopeSelector = createSelector(
    (state: RootState) => state.auth,
    (auth) => {
        const levelValue = LevelValue.Undefined
        const scopes = get(auth, 'current.idScopes', [])
        let highestScope: string = ''

        scopes.forEach((scope: string) => {
            const [level] = scope.split('.')
            const levelObj = USER_LEVEL_VALUES.find((o) => o.name === level)
            const value = get(levelObj, 'value', levelValue)

            // Set level name if it's higher level
            if (value > levelValue) {
                highestScope = scope
            }
        })

        return highestScope
    },
)

export const getScopeSelector = createSelector(
    (state: RootState) => state.auth,
    (auth) => {
        const scopes = get(auth, 'current.idScopes', [])
        let listScope: {
            vendorIds: string[]
            companyIds: string[]
        } = {
            vendorIds: [],
            companyIds: [],
        }

        scopes.forEach((scope: string) => {
            const [level, permission, vendorId, companyId] = scope.split('.')
            listScope.vendorIds.push(vendorId)
            listScope.companyIds.push(companyId)
        })

        return listScope
    },
)
