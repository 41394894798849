import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Site } from 'types'

interface SiteState {
    sites: Site[]
}

const initialState: SiteState = {
    sites: [],
}

const sites = createSlice({
    name: 'site',
    initialState: initialState,
    reducers: {
        setSites: (state, action: PayloadAction<Site[]>) => {
            state.sites = action.payload
        },
        addSite: (state, action: PayloadAction<Site>) => {
            state.sites = [...state.sites, action.payload]
        },
        editSite: (state, action: PayloadAction<Site>) => {
            state.sites = state.sites.map((item) => (item.id === action.payload.id ? action.payload : item))
        },
        removeSite: (state, action: PayloadAction<string>) => {
            state.sites = state.sites.filter((item) => item.id !== action.payload)
        },
    },
})

const { actions, reducer } = sites
export const { setSites, addSite, editSite, removeSite } = actions
export default reducer
