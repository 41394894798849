export enum UserPermission {
    Full = 'full',
    Execute = 'execute',
    ReadWrite = 'readwrite',
    Read = 'read',
    Demo = 'demo',
}

export enum UserLevel {
    Admin = 'admin',
    Vendor = 'vendor',
    Company = 'company',
    MaintenanceCompany = 'maintenanceCompany',
    Site = 'site',
    Undefined = 'undefined',
}

export enum DisclosurRange {
    PPA = 'ppa',
    Company = 'company',
}

export enum LevelValue {
    Admin = 99,
    Vendor = 3,
    Company = 2,
    Site = 1,
    Undefined = 0,
}

export enum AreaType {
    Hokkaido = 'hokkaido',
    Tohoku = 'tohoku',
    Tokyo = 'tokyo',
    Chubu = 'chubu',
    Hokuriku = 'hokuriku',
    Kansai = 'kansai',
    Chugoku = 'chugoku',
    Shikoku = 'shikoku',
    Kyushu = 'kyushu',
    Okinawa = 'okinawa',
}

export enum AreaTypeInJP {
    hokkaido = '北海道',
    tohoku = '東北',
    tokyo = '東京',
    chubu = '中部',
    hokuriku = '北陸',
    kansai = '関西',
    chugoku = '中国',
    shikoku = '四国',
    kyushu = '九州',
    okinawa = '沖縄',
}

export enum DateLabel {
    Today = 'today',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum HttpStatusCode {
    Unauthorized = 401,
}

export enum SurplusBuyerIsFit {
    NonFit = 0,
    Fit = 1,
}

export enum SurplusBuyerRounding {
    Floor = 1,
    Ceil = 2,
    Round = 3,
}

export enum InvoiceSampleStatus {
    Registered = '登録済み',
    NotRegistered = '未登録',
}

export enum CostType {
    InputCostDetail = 0,
    InputCostTotal = 1,
}

export enum InvoiceNumberStatus {
    Yes = 1,
    No = 2,
}

export enum OutputSuppression {
    Off = 0,
    On = 1,
}

export enum TypeSpan {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export enum CheckboxValue {
    Off = 0,
    On = 1,
}

export enum RadioValue {
    Off = 0,
    On = 1,
}

export enum AmountStatus {
    taxIncluded = 1,
    taxExcluded = 2,
}

export enum RegisterModeValue {
    Admin = 1,
    PPAUser = 2,
    AirUser = 3,
    AdvancedPermissionSetting = 0,
}

// Set default vendorID vppj when user not choose user serviceUsed (this only temporary)
export enum VendorValue {
    VPPJ = 'vppj',
}

export enum TypeOfComponent {
    Sites = 'sites',
    Users = 'users',
    Companies = 'companies',
    PPAOperators = 'ppa-operators',
    MaintenanceCompany = 'maintenance-company',
}

export enum MaintenanceCompanyTarget {
    AllChecked = '全企業',
}

export enum CheckedAllStatus {
    UnCheckedAll = 0,
    CheckedAll = 1,
}

export enum AccountType {
    Normal = 1,
    Temporarily = 2,
}

export enum OrderBy {
    Asc = 'asc',
    Desc = 'desc',
}

export enum TabPowerGenerationResult {
    Graph = 'graph',
    List = 'list',
}

export enum ESuppressionStatus {
    Active = 1,
    Inactive = 0,
}

export enum ELabelOptions {
    ZIP = 'ファイル別',
    SHEET = 'シート別',
}

export enum EDownloadTab {
    ACTUAL = 'actual',
    FORECAST = 'forecast',
}

export enum EInvoiceDataType {
    New = 1, // Data created in a batch with meter reading history support
}

export enum ESingleDownload {
    INDIVIDUAL = 'individual',
    BATCH = 'batch',
}

// Make enum for sheet name invoice
export enum EInvoiceSheetName {
    INVOICE = 'invoice',
    INVOICE_2 = 'invoice-2',
    INVOICE_NORMAL = 'normal',
    INVOICE_SURPLUS = 'surplus',
    INVOICE_NEW_SURPLUS = 'surplus-datatype-1',
}
