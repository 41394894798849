import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem, Typography } from '@material-ui/core'
import { PATH_NAME } from 'constants/index'
import { accountNameSelector } from 'selectors/authSelector'
import { useSelector } from 'react-redux'
import EnvLabel from '../EnvLabel'
import { AppEnv } from 'constants/env'

const Header: React.FC = () => {
    const classes = useStyles()
    const [anchorLogin, setAnchorLogin] = React.useState(null)
    const myPage = `${process.env.REACT_APP_AUTH_API_URL}/mypage`
    const accountName = useSelector(accountNameSelector)

    const handleClickAvatar = (event: any) => {
        setAnchorLogin(event.currentTarget)
    }

    const handleCloseAvatar = () => {
        setAnchorLogin(null)
    }

    const handleRedirect = () => {
        setAnchorLogin(null)
        window.open(myPage, 'ppa')
    }

    return (
        <div className={classes.root}>
            <EnvLabel env={process.env.REACT_APP_ENV as AppEnv} />
            <Typography variant="caption" component="span" className={classes.accountName} onClick={handleClickAvatar}>
                {accountName}
                <img src="/assets/images/arrow-down.svg" alt="arrow-down" style={{ marginLeft: 10 }} />
            </Typography>
            <Menu
                id="simple-menu2"
                anchorEl={anchorLogin}
                keepMounted
                open={Boolean(anchorLogin)}
                onClose={handleCloseAvatar}
                className={clsx(classes.menu, classes.menuLogin)}
            >
                <MenuItem className={clsx(classes.item, classes.itemLogin)} style={{ borderBottom: '1px solid #e3e3e3' }} onClick={handleRedirect}>
                    アカウント設定
                </MenuItem>
                <MenuItem className={clsx(classes.item, classes.itemLogin)} onClick={handleCloseAvatar} component={Link} to={PATH_NAME.LOGOUT}>
                    ログアウト
                </MenuItem>
            </Menu>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        height: '50px',
        boxShadow: '0 1px 3px #E4E7F1',
        backgroundColor: '#547096',
        zIndex: 2,
    },
    avatar: {
        marginRight: 40,
        marginLeft: 22,
        width: '32px',
        height: '32px',
        cursor: 'pointer',
    },
    setting: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    },
    menu: {
        boxShadow: '0 0 2px #e3e3e3',
        '& ul': {
            padding: 0,
        },
        '& .MuiPaper-root': {
            top: '55px !important',
            left: 'unset !important',
            right: '40px !important',
            borderRadius: 0,
        },
    },
    item: {
        fontSize: '12px',
        fontFamily: 'Hiragino Sans, sans-serif',
        background: '#FAFAFA',
        borderBottom: '1px solid #fff',
        height: '35px',
        width: '200px',
    },
    menuLogin: {
        '& .MuiPaper-elevation8': {
            boxShadow: 'none',
        },
        '& .MuiListItem-root.Mui-focusVisible': {
            backgroundColor: '#FAFAFA',
        },
        '& .MuiPaper-root': {
            top: '50px !important',
        },
    },
    itemLogin: {
        background: '#FAFAFA',
        boxShadow: 'none',
        borderBottom: 'none',
        '&.MuiListItem-button:hover': {
            backgroundColor: '#fafafa',
        },
    },
    accountName: {
        color: '#4A4A4A',
        marginRight: 40,
        fontFamily: 'Hiragino Sans, sans-serif',
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 'unset',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.6,
        },
    },
}))

export default Header
