import axiosClient from './axiosClient'

const authApi = {
    getScopes(accountId: string) {
        const url = `/api/accounts/${accountId}/scopes`
        return axiosClient.get(url)
    },
}

export default authApi
