import { TableCellProps } from '@material-ui/core'

export interface Site {
    title: string
    align: TableCellProps['align']
}

export const TITLE_SITE: Site[] = [
    { title: 'ロケーションコード', align: undefined },
    { title: 'サイト名', align: 'right' },
    { title: '発電電力（kW）', align: 'right' },
    { title: 'PR値（%）', align: 'right' },
    { title: '太陽光パネル容量（kW）', align: 'right' },
    { title: '日射量（kWh/㎡）', align: 'right' },
    { title: '日射強度（kW/㎡）', align: 'right' },
    { title: '気温（℃）', align: 'right' },
    { title: 'PCS出力（kW）', align: 'right' },
    { title: 'PCSステータス', align: 'right' },
]

export const HEADER_SITE_DOWNLOAD_CSV: string[] = [
    'ロケーションコード',
    'サイト名',
    '発電電力（kW）',
    'PR値（%）',
    '太陽光パネル容量（kW）',
    '日射量（kWh/㎡）',
    '日射強度（kW/㎡）',
    '気温（℃）',
    'PCS出力（kW）',
    'PCSステータス',
    '郵便番号',
    '住所',
    '適用開始日',
    '適用終了日',
    '供給単価（円/kWh）',
    'パネルメーカー',
    'パネル型番',
    'PCSメーカー',
    '機器型番',
]
