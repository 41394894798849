import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'

export const LoadingBackDrop = () => {
    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

const useStyles = makeStyles((theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
}))
