import { SETTING_APP_URL } from 'constants/index'

export const isDev = () => {
    console.log(process.env.REACT_APP_ENV)
    return process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'stg' || process.env.REACT_APP_ENV === 'prod'
}

/**
 * Check site management app (サイト管理アプリ)
 * @returns
 */
export const isSettingApp = () => {
    return window.location.origin === SETTING_APP_URL
}
