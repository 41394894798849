import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from 'slices/authSlice'
import filterReducer from 'slices/filterSlice'
import siteReducer from 'slices/siteSlice'
import paymentReducer from 'slices/paymentSlice'
const rootReducer = {
    auth: authReducer,
    filter: filterReducer,
    site: siteReducer,
    payment: paymentReducer,
}

const store = configureStore({
    reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export default store
