import { useState, useEffect, FC } from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrentUser } from 'hooks/useAuth'
import { PATH_NAME } from 'constants/index'

/**
 * Only access if logged in.
 * Redirect to login page if not logged in by default.
 */
export const AuthGuard: FC = ({ children }) => {
    const history = useHistory()
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
        ;(async () => {
            const currentUser = await getCurrentUser()
            if (currentUser) {
                setIsAuthenticated(true)
            } else {
                history.push(PATH_NAME.LOGIN)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isAuthenticated) return null

    return <>{children}</>
}
