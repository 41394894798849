import { TypeOfComponent } from 'constants/enum'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useFilterSearch from './useFilterSearch'

const useUnmountComponent = () => {
    const location = useLocation()
    const { selectFilter, type, filter } = useFilterSearch()

    const handleCheckType = (typeOf: string) => {
        return !location.pathname.includes(typeOf) && type === typeOf
    }

    useEffect(() => {
        if (handleCheckType(TypeOfComponent.Sites)) {
            selectFilter({
                ...filter,
                companyId: '',
                keyword: '',
            })
            return
        }
        if (handleCheckType(TypeOfComponent.Users)) {
            selectFilter({
                ...filter,
                companyId: '',
                keyword: '',
                level: '',
                filter: {},
            })
            return
        }
        if (handleCheckType(TypeOfComponent.Companies)) {
            selectFilter({
                ...filter,
                keyword: '',
            })
            return
        }
        if (handleCheckType(TypeOfComponent.PPAOperators)) {
            selectFilter({
                ...filter,
                keyword: '',
            })
            return
        }
        if (handleCheckType(TypeOfComponent.MaintenanceCompany)) {
            selectFilter({
                ...filter,
                keyword: '',
                vendorId: '',
            })
            return
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
}

export default useUnmountComponent
