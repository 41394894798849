import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrentUser } from 'hooks/useAuth'
import { PATH_NAME } from 'constants/index'

/**
 * Only access if not logged in.
 * Redirect to root page if logged in by default.
 */
export const GuestGuard: FC = ({ children }) => {
    const history = useHistory()
    const [isAuthenticated, setIsAuthenticated] = useState(true)

    useEffect(() => {
        ;(async () => {
            const currentUser = await getCurrentUser()
            if (currentUser) {
                history.push(PATH_NAME.HOME)
            } else {
                setIsAuthenticated(false)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isAuthenticated) return null

    return <>{children}</>
}
