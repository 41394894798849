import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PAYMENT_TYPE } from 'constants/common'
import { Payment as PaymentInterface } from 'types'
import moment from 'moment'
interface PaymentState {
    payments: {
        billingType: any
        downloadHeader: boolean
        payments: PaymentInterface[]
        paymentsAllSingleBilling: PaymentInterface[]
        filter: {
            billingMonth: string
            vendorId: string
            companyId: string
            siteId: string
            status: string
            searchCount: number
            tightening: string
        }
    }
}

const initialState: PaymentState = {
    payments: {
        billingType: PAYMENT_TYPE.INDIVIDUAL.value,
        downloadHeader: false,
        payments: [],
        paymentsAllSingleBilling: [],
        filter: {
            billingMonth: moment().format('YYYYMM'),
            vendorId: '',
            companyId: '',
            siteId: '',
            status: '',
            searchCount: 0,
            tightening: '',
        },
    },
}

const payments = createSlice({
    name: 'payments',
    initialState: initialState,
    reducers: {
        setBillingCompany: (state, action: PayloadAction<any>) => {
            state.payments.filter.companyId = action.payload
        },
    },
})

const { actions, reducer } = payments
export const { setBillingCompany } = actions
export default reducer
