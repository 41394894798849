import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/hooks'
import { setFilterSearch, setType } from 'slices'
import { filterSelector, typeSelector } from 'selectors'

const useFilterSearch = () => {
    const dispatch = useAppDispatch()
    const filter = useSelector(filterSelector)
    const type = useSelector(typeSelector)

    const selectFilter = (params: any) => {
        dispatch(setFilterSearch(params))
    }

    const selectType = (params: string) => {
        dispatch(setType(params))
    }

    return {
        filter,
        selectFilter,
        type,
        selectType,
    }
}

export default useFilterSearch
