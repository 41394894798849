import React, { Fragment } from 'react'
import { RouteItem } from 'utils/types'
import { Switch, Route } from 'react-router-dom'
import { PATH_NAME } from 'constants/index'
import { AuthGuard, GuestGuard } from 'guards'
import { useAppAbility } from 'casl/Can'
import { IAppAbility } from 'casl/ability'
import { isSettingApp } from 'utils/environment'

import { useAppAbility as useAppAbilityManagement } from 'caslManagement/Can'
import { IAppAbilityManagement } from 'caslManagement/ability'

import ManagementSystemBaseLayout from 'containers/Layouts/ManagementSystem/BaseLayout'
import SettingAppBaseLayout from 'containers/Layouts/SettingApp/BaseLayout'
import AbilityRoute from './AbilityRoute'

const routes: RouteItem[] = [
    {
        path: PATH_NAME.LOGIN,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Login')),
        guard: GuestGuard,
    },
    {
        path: PATH_NAME.LOGOUT,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Logout')),
        guard: AuthGuard,
    },
    {
        path: PATH_NAME.SETTING_APP.NO_AUTHORIZATION,
        exact: true,
        component: React.lazy(() => import('containers/Authorization/NoAuthorization')),
        guard: AuthGuard,
        hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'noAuthorization'),
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: ManagementSystemBaseLayout,
        routes: [
            {
                path: PATH_NAME.HOME,
                exact: true,
                component: React.lazy(() => import('containers/Home/home')),
            },
            {
                path: PATH_NAME.HOME2,
                exact: true,
                component: React.lazy(() => import('containers/Home')),
            },
            {
                path: PATH_NAME.SITE.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Site')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('select', 'Vendor'),
            },
            {
                path: PATH_NAME.SITE.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/SiteDetail')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('select', 'Vendor'),
            },
            {
                path: PATH_NAME.PAYMENT.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Payment')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Billing'),
            },
            {
                path: PATH_NAME.PAYMENT.INVOICE,
                exact: true,
                component: React.lazy(() => import('containers/Payment/Invoice/InvoiceDownload')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Billing'),
            },
            {
                path: PATH_NAME.INCIDENT.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Incident')),
            },
            {
                path: PATH_NAME.INCIDENT.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/Incident/Create')),
            },
            {
                path: PATH_NAME.INCIDENT.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/IncidentDetail')),
            },
            {
                path: PATH_NAME.INFORMATION_LIST.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/InformationList')),
            },
            {
                path: PATH_NAME.DOWNLOAD.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Download')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Download'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Project'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.MULTIPLE_PROJECTS,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement/MultipleProjects')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Project'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement/Create')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('create', 'Project'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.EDIT,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement/Create')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('modify', 'Project'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.EDIT_ACTUAL_COST,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement/Detail/components/EditTableActualCost')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('modify', 'Project'),
            },
            {
                path: PATH_NAME.PROJECT_MANAGEMENT.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/ProjectManagement/Detail')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Project'),
            },
            {
                path: PATH_NAME.NOTE.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/ReleaseNotes')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Note'),
            },
            {
                path: PATH_NAME.SCHEDULE.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Schedule')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'Schedule'),
            },
            {
                path: PATH_NAME.MONITOR_SETTINGS.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/MonitorSetting')),
                hasAbility: (ability: IAppAbilityManagement) => ability.can('view', 'MonitorSetting'),
            },
        ],
    },
]

const settingRoutes: RouteItem[] = [
    {
        path: PATH_NAME.LOGIN,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Login')),
        guard: GuestGuard,
    },
    {
        path: PATH_NAME.LOGOUT,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Logout')),
        guard: AuthGuard,
    },
    {
        path: PATH_NAME.SETTING_APP.NO_AUTHORIZATION,
        exact: true,
        component: React.lazy(() => import('containers/SettingApp/Authorization/NoAuthorization')),
        guard: AuthGuard,
        hasAbility: (ability: IAppAbility) => ability.can('view', 'noAuthorization'),
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: SettingAppBaseLayout,
        routes: [
            {
                path: PATH_NAME.HOME,
                exact: true,
                component: React.lazy(() => import('containers/SettingApp/Home')),
            },
            {
                path: PATH_NAME.SETTING_APP.PPA_OPERATOR.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Vendor'),
            },
            {
                path: PATH_NAME.SETTING_APP.PPA_OPERATOR.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Vendor'),
            },
            {
                path: PATH_NAME.SETTING_APP.PPA_OPERATOR.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Vendor'),
            },
            {
                path: PATH_NAME.SETTING_APP.COMPANY.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Company')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Company'),
            },
            {
                path: PATH_NAME.SETTING_APP.COMPANY.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/Company/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Company'),
            },
            {
                path: PATH_NAME.SETTING_APP.COMPANY.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/Company/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Company'),
            },
            {
                path: PATH_NAME.SETTING_APP.SITE.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/SiteSetting')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Site'),
            },
            {
                path: PATH_NAME.SETTING_APP.SITE.MODIFY,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Site'),
            },
            {
                path: PATH_NAME.SETTING_APP.SITE.MODIFY_DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Site'),
            },
            {
                path: PATH_NAME.SETTING_APP.SITE.EQUIPMENT_MODIFY,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Site'),
            },
            {
                path: PATH_NAME.SETTING_APP.USER.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/User')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Account'),
            },
            {
                path: PATH_NAME.SETTING_APP.USER.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/User/AddEdit')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Account'),
            },
            {
                path: PATH_NAME.SETTING_APP.USER.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/User/AddEdit')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Account'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'MaintenanceCompany'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany/Create')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'MaintenanceCompany'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany/Create')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'MaintenanceCompany'),
            },
        ],
    },
]

const renderRoutes = (routes: RouteItem[], ability: any) => {
    return routes ? (
        <Switch>
            {routes.map((route: RouteItem, idx: number) => {
                const Guard = route.guard || Fragment
                const Layout = route.layout || Fragment
                const Component = route.component
                const hasAbility = route.hasAbility

                return (
                    <Route
                        key={`routes-${idx}`}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any) => (
                            <Guard>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes, ability)
                                    ) : (
                                        <AbilityRoute hasAbility={hasAbility}>
                                            <Component {...props} />
                                        </AbilityRoute>
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                )
            })}
        </Switch>
    ) : null
}

function Routes() {
    const abilitySetting = useAppAbility()
    const abilityManagement = useAppAbilityManagement()
    const rootRoutes = isSettingApp() ? settingRoutes : routes

    return isSettingApp() ? renderRoutes(rootRoutes, abilitySetting) : renderRoutes(rootRoutes, abilityManagement)
}

export default Routes
