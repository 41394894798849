import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Header from './Header'
import AppMenu from './Slidebar/AppMenu'

const BaseLayout: FC = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Header />
            <AppMenu />
            <div className={classes.main}>{children}</div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Hiragino Sans, sans-serif',
        background: '#EDF1F8',
        minHeight: '100vh',
    },
    main: {
        marginTop: 50,
        marginLeft: 200,
        minHeight: 'calc(100vh - 50px)',
        width: '100%',
        background: '#EDF1F8',
    },
}))

export default BaseLayout
