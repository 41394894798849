import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from 'app/store'
import { Provider } from 'react-redux'

import { ApolloProvider } from '@apollo/client'
import { client } from 'api/apolloClient'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BaseTheme } from 'themes/BaseTheme'
import { SnackbarProvider } from 'notistack'
import { LoadingProvider } from 'contexts'
import { AbilityContext } from 'casl/Can'
import ability from 'casl/ability'
import { AbilityContextManagement } from 'caslManagement/Can'
import abilityManagement from 'caslManagement/ability'

// TODO: Only show it on local
if (false) {
    ;(window as any).ability = ability
}

const theme = BaseTheme

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <AbilityContextManagement.Provider value={abilityManagement}>
                    <AbilityContext.Provider value={ability}>
                        <Suspense fallback={<></>}>
                            {/* Do something while loading component*/}
                            <BrowserRouter>
                                <MuiThemeProvider theme={theme}>
                                    <SnackbarProvider maxSnack={3}>
                                        <LoadingProvider>
                                            <App />
                                        </LoadingProvider>
                                    </SnackbarProvider>
                                </MuiThemeProvider>
                            </BrowserRouter>
                        </Suspense>
                    </AbilityContext.Provider>
                </AbilityContextManagement.Provider>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
