import React, { useEffect } from 'react'
import clsx from 'clsx'
import { startsWith } from 'lodash'
import { Link, useLocation } from 'react-router-dom'

import { PATH_NAME } from 'constants/index'
import { useAppAbility } from 'casl/Can'
import { Subjects } from 'casl/ability'

import { List, ListItem, ListItemText, makeStyles, Collapse } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

type AppMenuItems = {
    name: string
    icon: Object
    items: AppMenuItem[]
    openItemId: string
}

type AppMenuItem = {
    name: string
    link: string
    subject: Subjects
}

export default function AppMenuSetting() {
    const classes = useStyles()
    const location = useLocation()
    const ability = useAppAbility()

    const [openedItemId, setOpenedItemId] = React.useState('/site-setting')

    useEffect(() => {
        if (location) {
            switch (handleSplitPathName(location.pathname)) {
                case PATH_NAME.SETTING_APP.SITE.INDEX:
                    setOpenedItemId('/site-setting')
                    break
                case PATH_NAME.SETTING_APP.USER.INDEX:
                    setOpenedItemId('/account-setting')
                    break
                case PATH_NAME.HOME:
                case PATH_NAME.SETTING_APP.PPA_OPERATOR.INDEX:
                case PATH_NAME.SETTING_APP.COMPANY.INDEX:
                case PATH_NAME.MAINTENANCE_COMPANY.INDEX:
                    setOpenedItemId('/company-setting')
                    break
                // case PATH_NAME.SETTING_APP.BASIC_CONFIG.INDEX:
                //     setOpenedItemId('/config-setting')
                //     break
                default:
                    break
            }
        }
    }, [location])

    const handleClick = (e: any) => {
        let clickedItemId = e.currentTarget.id
        if (openedItemId === clickedItemId) {
            setOpenedItemId('')
        } else {
            setOpenedItemId(clickedItemId)
        }
    }

    const appMenuItems: AppMenuItems[] = [
        {
            // Site settings
            name: 'サイト設定',
            icon: <img src={'/assets/images/site-setting.svg'} alt="" style={{ marginRight: 10 }} />,
            items: [{ name: 'サイト設定', link: PATH_NAME.SETTING_APP.SITE.INDEX, subject: 'Site' }],
            openItemId: '/site-setting',
        },
        {
            // User settings
            name: '利用者設定',
            icon: <img src={'/assets/images/user-setting.svg'} alt="" style={{ marginRight: 20 }} />,
            items: [{ name: '利用者', link: PATH_NAME.SETTING_APP.USER.INDEX, subject: 'Account' }],
            openItemId: '/account-setting',
        },
        {
            // Company settings
            name: '会社設定',
            icon: <img src={'/assets/images/company-setting.svg'} alt="" style={{ marginRight: 16 }} />,
            items: [
                { name: '事業者', link: PATH_NAME.SETTING_APP.PPA_OPERATOR.INDEX, subject: 'Vendor' },
                { name: '利用企業', link: PATH_NAME.SETTING_APP.COMPANY.INDEX, subject: 'Company' },
                { name: 'メンテナンス事業者', link: PATH_NAME.MAINTENANCE_COMPANY.INDEX, subject: 'MaintenanceCompany' },
            ],
            openItemId: '/company-setting',
        },
        // {
        //     // Basic configuration
        //     name: '基本設定',
        //     icon: <img src={'/assets/images/basic-configuration.svg'} alt="" style={{ marginRight: 19 }} />,
        //     items: [{ name: 'アプリ', link: PATH_NAME.SETTING_APP.BASIC_CONFIG.INDEX }],
        //     subject: 'Vendor',
        //     openItemId: '/config-setting',
        // },
    ]

    const handleSplitPathName = (name: string) => {
        return '/' + name.split('/')[1]
    }

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="secondary mailbox folders" className={classes.nav}>
                {appMenuItems.map((appMenuItem: AppMenuItems, index: number) => (
                    <div key={index}>
                        <ListItem id={appMenuItem.openItemId} className={clsx(classes.item, classes.siteLink)} button onClick={handleClick}>
                            {appMenuItem.icon}
                            <ListItemText primary={appMenuItem.name} />
                            {openedItemId === appMenuItem.openItemId ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={openedItemId === appMenuItem.openItemId} timeout="auto" unmountOnExit className={classes.customCollapse} key={index}>
                            <List component="div" disablePadding>
                                {appMenuItem.items
                                    .filter((menuItem: AppMenuItem) => ability.can('view', menuItem.subject))
                                    .map((item: any, i: number) => (
                                        <ListItem
                                            key={i}
                                            button
                                            className={clsx(classes.item, classes.nested2)}
                                            component={Link}
                                            to={item.link}
                                            selected={startsWith(item.link, handleSplitPathName(location.pathname))}
                                        >
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    ))}
                            </List>
                        </Collapse>
                    </div>
                ))}
            </List>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 180,
        backgroundColor: '#76A2AA',
        position: 'fixed',
        height: '100%',
        zIndex: 1,
        boxShadow: '0 1px 3px #707070',
    },
    nav: {
        paddingTop: 40,
    },
    item: {
        maxHeight: 45,
        color: '#76A2AA',
        '& .MuiTypography-body1': {
            fontFamily: 'Hiragino Sans, sans-serif',
            fontSize: '15px',
            fontWeight: '300 !important',
            letterSpacing: '-1.5px',
        },
        '&.Mui-selected': {
            background: 'unset',
        },
        '&.Mui-selected .MuiTypography-body1': {
            fontWeight: '300 !important',
        },
        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#E6E6E6',
        },
    },

    siteLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#FFF',
        width: '100%',
        cursor: 'pointer',
    },

    customCollapse: {
        maxHeight: 340,
    },

    nested2: {
        paddingLeft: 47,
        paddingRight: 5,
        background: '#FAFAFA',
        '& .MuiTypography-body1': {
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&.MuiListItem-button:hover': {
            backgroundColor: '#E6E6E6',
        },
    },
}))
